import React from 'react'
import PostCardContentVertical from './post-card-content-vertical'
import PostCardContentHorizontal from './post-card-content-horizontal'
import SharePostLink from './share-post-link'

const PostCard = (props) => {
  const index = (props.index + 1);

  //TODO - Can figure out horizontal layout later on.
  // let hasHorizontalLayout = (index % 10 == 4) || (index % 10 === 5);
  let hasHorizontalLayout = true;

  const PostCardContent = () => {
    if (hasHorizontalLayout) {
      return <PostCardContentHorizontal post={props.post} />
    } else {
      return <PostCardContentVertical post={props.post} />
    }
  }

  const width = hasHorizontalLayout ? 'lg:w-1/2 md:w-full sm:w-full' : 'lg:w-1/3 md:w-1/2 sm:w-full';

  const postAnchor = props.post.name.toLowerCase().replace(/ /g, '_')

  return (
    <div className={`md:px-5 mb-10 ${width}`}>
      <div className="flex flex-col shadow-card h-full bg-white rounded-b">
        <div className="post-gradient px-5 py-2 rounded-t flex justify-between">
          <h3 className="font-normal text-text-darkest flex items-center" id={postAnchor}>
            {props.post.name}

            <SharePostLink anchor={postAnchor}/>
          </h3>

          <span className="opacity-50">#{index}</span>
        </div>

        <div className="flex flex-col p-5 pt-0 h-full">
          <PostCardContent />
        </div>
      </div>
    </div>
  )
}

export default PostCard
