import React from 'react'
import PostCard from './elements/post-card'

const PostList = (props) => (
  <div className="flex flex-col">
    <h2 className="flex-1 text-center font-normal text-darker opacity-75">
      {props.title}
    </h2>

    <div className="flex flex-wrap mt-10 md:-mx-5 justify-center">
      {
        props.posts.map((post, index) => (
          <PostCard key={index} index={index} post={post.node}/>
        ))
      }
    </div>
  </div>
)

export default PostList
