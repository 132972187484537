import React from 'react'
import { Link } from 'gatsby'

const Breadcrumbs = ({children}) => {
  return (
    <div className="flex">
      <Link to="/">Home</Link>

      <span className="mx-1 text-grey-light">/</span>

      <p className="text-grey-light">{children}</p>
    </div>
  )
}

export default Breadcrumbs
