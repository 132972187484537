import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const ItemListSchema = (props) => {
  let { name, items, url } = props

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl,
            }
          }
        }
      `}
        render={data => {
          items = items.map((item, index) => {
            return {
              "@type": "ListItem",
              "position": (index + 1),
              "item": {
                "name": item,
                "url": `${url}#${item.toLowerCase().replace(/ /g, '_')}`
              }
             }
          })

          const ItemListSchema = {
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListOrder": "http://schema.org/ItemListOrderDescending",
            "name": name,
            "numberOfItems": items.length,
            "itemListElement": [
               ...items
            ]
          }

          const schema = JSON.stringify(ItemListSchema).replace(/(&quot;)/g,"\"")

          return (
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: schema}}></script>
          )
        }
      }
    />
  )
}

export default ItemListSchema
