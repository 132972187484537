import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from "gatsby"

class PreviousCategory extends React.Component {
  render() {
    const { category, categories } = this.props;
    let currentIndex = categories.findIndex(cat => cat.name === category.name);

    // Loop back to the end if we're already viewing the
    // first category in the list.
    if (currentIndex === 0) {
      currentIndex = categories.length;
    }

    const cat = categories[currentIndex - 1];

    return (
      <Link className="flex items-center hover:text-blue-dark" to={ `/${cat.slug}` }>
        <i className="mr-2 icon-right-arrow text-xl" style={{ transform: `rotate(180deg)` }}></i>

        { cat.name }
      </Link>
    )
  }
}

export default (props) => {
  return (
    <StaticQuery
      query={
        graphql`
          query {
            allCategoriesJson {
              edges {
                node {
                  name
                  slug
                }
              }
            }
          }
        `
      }
      render={data => {
        const categories = data.allCategoriesJson.edges.map(category => category.node)

        return (
          <PreviousCategory
            categories={categories}
            category={props.category}
          />
        )
      }}
    />
  )
}
