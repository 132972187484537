import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo'
import PostsList from '../components/posts-list'
import BreadCrumbs from '../components/breadcrumbs'
import PreviousCategory from '../components/elements/previous-category'
import NextCategory from '../components/elements/next-category'
import BreadcrumbSchema from '../components/json-schema/breadcrumb-schema'
import ItemListSchema from '../components/json-schema/item-list-schema'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { graphql } from "gatsby"

class PostTemplate extends React.Component {
  render() {
    const { siteMetadata } = this.props.data.site
    const category = this.props.data.allCategoriesJson.edges[0].node

    // const title = category.title

    const ogImage = `${siteMetadata.siteUrl}/images/categories/${this.props.data.allCategoriesJson.edges[0].node.og_image_name}`;

    const posts = this.props.data.allResourcesJson ? this.props.data.allResourcesJson.edges : [];

    const pageLink = `${siteMetadata.siteUrl}/${this.props.pageContext.category.slug}`

    // Headings
    const h1 = category.copy_heading;
    const h2 = `Your Go-To Top ${posts.length} List`;

    const seoTitle = `Top ${posts.length} ${category.title}`

    return (
      <Layout category={this.props.pageContext.categoryTitle} location={this.props.location}>
        <SEO
          title={seoTitle}
          description={this.props.pageContext.description}
          keywords={this.props.pageContext.keywords}
          meta={[
            {
              property: 'og:image',
              content: ogImage,
            },
            {
              property: 'twitter:image',
              content: ogImage,
            },
            {
              property: 'og:url',
              content: pageLink
            }
          ]}
        />

        <div key="white-content">
          <div className="flex relative items-center lg:pt-0 pt-10">
            <div className="absolute pin-l pin-t lg:pin-none">
              <BreadCrumbs>{this.props.pageContext.category}</BreadCrumbs>
            </div>
          </div>

          <div className="mt-12 flex items-center md:-mx-5 flex-col md:flex-row">
            <div className="w-full px-5 mb-8 md:w-2/5 md:mb-0 lazy-load-container">
              <LazyLoadImage
                effect="blur"
                alt={category.name}
                src={`/images/categories/svg/${category.svg_image_name}`}
                className="w-full h-full"
                style={{ maxHeight: '400px' }}
              />
            </div>

            <div className="w-full px-5 md:w-3/5">
              <p className="uppercase opacity-50">{category.copy_subtitle}</p>

              <h1 className="my-2 uppercase text-3xl">{category.copy_heading}</h1>

              <p>{category.copy}</p>
            </div>
          </div>
        </div>

        <PostsList title={h2} posts={posts} />

        <div className="flex justify-between my-6 md:mt-12">
          <PreviousCategory category={category} />

          <p className="text-grey-darker mr-2 hidden md:block">Explore Other Categories</p>

          <NextCategory category={category} />
        </div>

        <BreadcrumbSchema links={[{name: this.props.pageContext.category, link: pageLink}]}/>

        <ItemListSchema name={h1} items={posts.map(post => post.node.name)} url={pageLink} />
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($category: String!) {
    allResourcesJson (filter: {category: {eq: $category}}) {
      edges {
        node {
          name,
          description,
          category,
          image_name,
          external_link,
          license_link
        }
      }
    }
    allCategoriesJson (filter: {name: {eq: $category}}) {
      edges {
        node {
          name,
          title,
          og_image_name,
          svg_image_name,
          copy,
          copy_heading,
          copy_subtitle
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
