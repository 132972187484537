import React from 'react'
import ButtonPrimaryLink from './button-primary-link'
import { withPrefix } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const content = (props) => {
  const path = props.post.image_name.includes('.svg') ?
    withPrefix(`/images/resources/svg/${props.post.image_name}`) :
    withPrefix(`/images/resources/${props.post.image_name}`);

  return (
    <div className="flex flex-col h-full justify-between">
      <div className="flex mt-8 -mx-4 flex-wrap">
        <div className="flex items-center justify-center md:w-1/3 w-full mb-8 md:mb-0 px-4">
          <a
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={props.post.external_link}
            className="flex justify-center items-center mb-2"
            style={{
              width: `120px`,
              height: `120px`,
            }}
          >
            <LazyLoadImage
              effect="blur"
              alt={props.post.name}
              src={path}
              width="120"
              height="120"
              className="h-full"
            />
          </a>
        </div>

        <p className="mb-4 md:mb-5 md:w-2/3 w-full px-4">{props.post.description}</p>
      </div>

      <div className="flex -mx-4 flex-wrap">
        <div className="w-full mb-3 md:mb-0 md:w-1/3 px-4 flex">
          {
            props.post.license_link !== '' &&

            <a
              href={props.post.license_link}
              target="_blank"
              rel="noopener noreferrer"
              className="button text-center button-secondary w-full"
            >View License</a>
          }
        </div>

        <div className="w-full md:w-2/3 px-4 flex">
          <ButtonPrimaryLink href={props.post.external_link}>
            Check it out!
          </ButtonPrimaryLink>
        </div>
      </div>
    </div>
  )
}

export default content
