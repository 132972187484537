import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';

class SharePostLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    }

    this.handleCopy = this.handleCopy.bind(this);
  }

  componentDidMount() {
    this.setState({
      link: `${window.location.origin}${window.location.pathname}#${this.props.anchor}`
    })
  }

  handleCopy() {
    this.setState({
      copied: true
    });

    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 1200)
  }

  render() {
    const copyClass = this.state.copied ? 'opacity-100' : 'opacity-0';

    return (
      <div className="flex items-center">
        <CopyToClipboard
          text={this.state.link}
          onCopy={this.handleCopy}
        >
          <i className="icon-share opacity-25 text-sm ml-3 cursor-pointer hover:text-blue hover:opacity-100"></i>
        </CopyToClipboard>

        <span
          className={`ml-3 text-sm ${copyClass}`}
          style={{
            transition: 'all 0.3s'
          }}
        >Link Copied</span>
      </div>
    )
  }
}

export default SharePostLink
