import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const BreadcrumbSchema = (props) => {
  const links = props.links.map((link, index) => {
    return {
      "@type": "ListItem",
      "position": (index + 2),
      "item":{
        "@id": link.link,
        "name": link.name
      }
    }
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl,
            }
          }
        }
      `}
        render={data => {
          const siteUrl = data.site.siteMetadata.siteUrl;

          const BreadcrumbSchema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":[
               {
                  "@type": "ListItem",
                  "position": 1,
                  "item":{
                     "@id": siteUrl,
                     "name": "Home"
                  }
               },
               ...links
            ]
          }

          const schema = JSON.stringify(BreadcrumbSchema).replace(/(&quot;)/g,"\"")

          return (
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: schema}}></script>
          )
        }
      }
    />
  )
}

export default BreadcrumbSchema
