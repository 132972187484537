import React, { Component } from 'react'
import posed from 'react-pose'

const Arrow = posed.div({
  hidden: {
    opacity: 0,
    left: `50%`,
    transition: { duration: 300 }
  },
  visible: {
    opacity: 1,
    left: `85%`,
    transition: { duration: 300 }
  }
});

class PrimaryButtonLink extends Component {
  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.state = {
      isVisible: false,
    };
  }

  handleMouseEnter() {
    this.setState(this.toggleHoverState(true));
  }

  handleMouseLeave() {
    this.setState(this.toggleHoverState(false));
  }

  toggleHoverState(state) {
    return {
      isVisible: state,
    };
  }

  render() {
    const { isVisible } = this.state;

    return (
      <a
        href={this.props.href}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="button text-center button-primary w-full relative flex items-center justify-center"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Arrow className="absolute flex" pose={isVisible ? 'visible' : 'hidden'}>
          <i className="icon-right-arrow text-xl"></i>
        </Arrow>

        {this.props.children}
      </a>
    )
  }
}

export default PrimaryButtonLink
